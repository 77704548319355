.modal_overflow {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: -1;
    overflow-y: auto;
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s ease;

    &.show {
        opacity: 1;
        visibility: visible;
        z-index: 1000;
    }
}

.modal_container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 920px;
    padding: 50px;
    padding-bottom: 70px;
    background: #F0F2F6;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
    border-radius: 40px;
    overflow: hidden;

    @include mediaToDown($container-tablet) {
        width: 100%;
        height: 100%;
        border-radius: 0;
        overflow-y: scroll;
    }

    @include mediaToDown($container-mobile) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 60px 20px 30px 20px;
        padding-bottom: 30px;
    }

    &:after {
        content: '';
        position: absolute;
        right: 0;
        bottom: -40px;
        width: 365px;
        height: 410px;
        background: url('../../images/thanks_img.svg') no-repeat center center/cover;

        @include mediaToDown($container-tablet) {
            content: none;
        }
    }
}

.modal_close {
    position: absolute;
    right: 50px;
    top: 50px;
    width: 21px;
    height: 21px;
    background: url('../../images/close.svg') no-repeat center center/cover;
    cursor: pointer;

    @include mediaToDown($container-tablet) {
        right: 15px;
        top: 15px;
        width: 16px;
        height: 16px;
    }
}

.modal_title {
    font-weight: 500;
    font-size: 36px;
    line-height: 48px;
    color: color('black');
    margin-bottom: 41px;

    @include mediaToDown($container-mobile) {
        font-size: 18px;
        line-height: 26px;
        text-align: center;
        margin-bottom: 8px;

        br {
            display: none;
        }
    }

    @include mediaToDown(360px) {
        font-size: 15px;
        line-height: 22px;
    }
}

.modal_subtitle {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 120px;

    @include mediaToDown($container-tablet) {
        margin-bottom: 50px;
    }

    @include mediaToDown($container-mobile) {
        font-size: 15px;
        line-height: 23px;
        text-align: center;
        margin-bottom: 20px;
    }

    @include mediaToDown(360px) {
        font-size: 13px;
        line-height: 23px;
    }
}

.modal_btn_wrap {
    width: 330px;

    @include mediaToDown($container-tablet) {
        width: 100%;
    }

    button {
        text-align: center;
    }
}

.modal_image {
    display: none;

    @include mediaToDown($container-tablet) {
        display: block;
        width: 240px;
        height: 300px;
        margin: 0 auto;
        margin-bottom: 30px;

        img {
            display: block;
            width: 100%;
        }
    }

    @include mediaToDown(360px) {
        width: 55%;
        margin-bottom: 15px;
    }
}
