.loan_loader_wrap {
    text-align: center;
}

.loan_loader_text {
    width: 300px;
    padding-top: 10px;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #4AC054;
    margin: 0 auto;

    @include mediaToDown($container-mobile) {
        font-size: 18px;
        line-height: 24px;
        width: 100%;
    }
}
