.form_row {
    position: relative;
    z-index: 2;

    &.input_phone {
        input {
            &:hover ~ label {
                font-size: 16px;
                top: 28%;
                left: 20px;
            }
        }
    }

    input {
        height: 80px;
        width: 100%;
        padding: 20px;
        padding-top: 38px;
        border: none;
        border-radius: 10px;
        background: #F0F2F6;
        color: #4D4D59;
        font-weight: 500;
        font-size: 20px;
        font-family: $font-family-text;

        &:not(:placeholder-shown) ~ label {
            font-size: 16px;
            top: 28%;
            left: 20px;

            @include mediaToDown($container-mobile) {
                font-size: 12px;
                left: 14px;
            }

            &.error {
                font-size: 16px;
                color: #ff6567;
                top: auto;
                bottom: -20px;
                left: 2px;
                transform: translateY(0);

                @include mediaToDown($container-mobile) {
                    font-size: 12px;
                }
            }
        }

        &:focus ~ label {
            font-size: 16px;
            top: 28%;
            left: 20px;

            @include mediaToDown($container-mobile) {
                font-size: 12px;
                left: 14px;
            }

            &.error {
                font-size: 16px;
                color: #ff6567;
                top: auto;
                bottom: -20px;
                left: 2px;
                transform: translateY(0);

                @include mediaToDown($container-mobile) {
                    font-size: 12px;
                }
            }
        }

        &:hover,
        &:focus {
            border-color: #ffb701;
        }

        @include mediaToDown($container-mobile) {
            height: 60px;
            font-size: 18px;
            line-height: 28px;
            padding-left: 14px;
        }
    }

    .error_text {
        position: absolute;
        bottom: -26px;
        left: 0;
        opacity: 0;
        visibility: hidden;
        color: #EB5757;
        font-size: 16px;
        line-height: 19px;
        transition: opacity .2s ease-in-out;
        margin-top: 9px;

        &.show_error {
            opacity: 1;
            visibility: visible;

            @include mediaToDown($container-mobile) {
                position: static;
            }
        }

        @include mediaToDown($container-tablet) {
            bottom: -30px;
            font-size: 14px;
        }

        @include mediaToDown($container-mobile) {
            font-size: 9px;
            margin-top: 0;
        }
    }

    &.error {
        input {
            background: #FFEFF0;
            box-shadow: inset 0px -2px 0px #EB5757;
            border-radius: 10px 10px 0px 0px;
            color: #EB5757;
        }

        label {
            color: #EB5757;
        }

        .error_text {
            opacity: 1;
            visibility: visible;
        }

        @include mediaToDown($container-mobile) {
            .error_text {
                position: static;
                opacity: 1;
                visibility: visible;
                transition: opacity .2s ease;
            }
        }
    }

    &.valid {
        input {
            background: #F6F6F7;
            box-shadow: inset 0px -2px 0px #00CC45;
            border-radius: 10px 10px 0px 0px;
        }

        label {
            color: #4AC054;
        }
    }

    label {
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
        font-size: 24px;
        color: color('grey');
        pointer-events: none;
        transition: all 0.2s ease;

        &.error {
            font-size: 13px;
            color: #ff6567;
            top: auto;
            bottom: -20px;
            left: 2px;
            transform: translateY(0);
        }

        @include mediaToDown($container-mobile) {
            font-size: 18px;
            left: 14px;
        }
    }

    &.row_white {
        input {
            background: #fff;
        }

        &.error {
            input {
                background: #FFEFF0;
                box-shadow: inset 0px -2px 0px #EB5757;
                border-radius: 10px 10px 0px 0px;
            }
        }
    }
}

.motivation_wrap {
    position: relative;
}

.motivation_info {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 305px;
    padding: 16px 20px;
    background: #4AC054;
    color: #fff;
    font-size: 16px;
    line-height: 20px;
    border-radius: 10px;
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s ease 1.9s, visibility 3s ease 0s, bottom .3s ease 2.3s;
    pointer-events: none;

    &.active {
        opacity: 1;
        bottom: 90px;
        visibility: visible;
        z-index: 1;
        transition: opacity .3s ease 0s, visibility .3s ease 0s, bottom .3s ease 0s;

        @include mediaToDown($container-mobile) {
            bottom: 65px;
        }
    }

    @include mediaToDown($container-mobile) {
        width: 100%;
    }
}

.form_row_checbox {
    width: 100%;
    text-align: right;
    padding-right: 85px;
    padding-top: 12px;

    @include mediaToDown($container-tablet) {
        padding-right: 0;
        text-align: center;
    }

    @include mediaToDown($container-mobile) {
        padding-top: 0;
    }
}

.checkbox {
    display: none;
}

.checkbox-custom {
    position: relative;
    width: 15px;
    height: 15px;
    background-color: #C7C7CF;
    border-radius: 2px;
}

.checkbox-custom,
.checkbox-label {
    display: inline-block;
    vertical-align: middle;
}

.checkbox-label {
    margin-left: 6px;
    margin-top: 3px;
    color: #C7C7CF;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    a {
        text-decoration: none;
    }
}

.checkbox:checked + .checkbox-custom {
    background: transparent;
}

.checkbox:checked ~ .checkbox-label {
    color: #4AC054;
}

.checkbox:checked + .checkbox-custom::before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 16px;
    height: 17px;
    background: url('../../images/check.svg') no-repeat center center/cover;
}

.form_row_select_custom {
    width: 508px;

    &.show_error {
        .error_text {
            opacity: 1;
            visibility: visible;
        }
    }

    @include mediaToDown($container-tablet) {
        width: 48%;
    }

    @include mediaToDown($container-mobile) {
        width: 100%;
        margin-bottom: 10px;
    }

    .error_text {
        position: absolute;
        bottom: -26px;
        left: 0;
        opacity: 0;
        visibility: hidden;
        color: #EB5757;
        font-size: 16px;
        line-height: 19px;
        transition: opacity .2s ease;
        margin-top: 9px;

        @include mediaToDown($container-tablet) {
            bottom: -20px;
            font-size: 14px;
        }

        @include mediaToDown($container-mobile) {
            font-size: 11px;
            margin-top: 0;
        }
    }
}

.custom_form_control {
    width: 100%;
    height: 80px;
    border-radius: 10px;
    background: #F0F2F6;

    @include mediaToDown($container-mobile) {
        height: 60px;
    }

    &:after {
        content: '';
        position: absolute;
        right: 28px;
        top: 50%;
        transform: translateY(-50%);
        width: 13px;
        height: 17px;
        background: url('../../images/select_arrow.svg') no-repeat center center/contain;
        pointer-events: none;

        @include mediaToDown($container-mobile) {
            right: 17px;
        }
    }
}

.range_slider_wrap {
    position: relative;
    width: 100%;
    height: 80px;
    border-radius: 10px 10px 0 0;
    background: #F0F2F6;
    padding: 15px 20px;

    @include mediaToDown($container-mobile) {
        height: 60px;
        padding: 15px 13px;
    }

    label {
        position: absolute;
        top: 31%;
        left: 20px;
        transform: translateY(-50%);
        font-size: 16px;
        color: #8e8e9e;
        pointer-events: none;
        transition: all 0.2s ease;

        @include mediaToDown($container-mobile) {
            font-size: 12px;
            line-height: 14px;
            left: 14px;
        }
    }

    input {

        &.range_slider_text_val {
            font-family: $font-family-text;
            font-weight: 500;
            font-size: 20px;
            background: transparent;
            box-shadow: none;
            border: none;
            padding-top: 24px;
            color: #4D4D59;

            @include mediaToDown($container-mobile) {
                font-size: 18px;
                line-height: 28px;
                padding-top: 12px;
            }
        }

    }
}


.range_nouislider {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;

    .noUi-horizontal {
        height: 3px;
    }

    .noUi-connect {
        background: #4AC054;
    }

    .noUi-connects {
        border-radius: 0;
    }

    .noUi-target {
        background: transparent;
        border-radius: 0;
        border: none;
        box-shadow: none;
    }

    .noUi-horizontal .noUi-handle {
        top: -8px;
        right: -11px;
        width: 20px;
        height: 20px;
        background: #4AC054;
        border: 2px solid #FFFFFF;
        border-radius: 50%;
        box-sizing: border-box;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 1px 1px rgba(0, 0, 0, 0.05);

        @include mediaToDown($container-mobile) {
            width: 18px;
            height: 18px;
        }

        &:before, &:after {
            content: none;
        }
    }
}

.react-datepicker-wrapper {
    width: 100%;
}

.credit_form_content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 20px;

    @include mediaToDown($container-mobile) {
        padding-top: 10px;
    }

    .form_row {
        width: 330px;

        @include mediaToDown($container-tablet) {
            width: 100%;
            margin-bottom: 20px;
        }

        @include mediaToDown($container-mobile) {
            margin-bottom: 10px;
        }

        input {
            background: #F0F2F6;
        }
    }

    &.hint_inputs {

        .form_row {

            input {
                background: #FFEFF0;
                box-shadow: inset 0px -2px 0px #EB5757;
                border-radius: 10px 10px 0px 0px;
            }

            label {
                color: #EB5757;
            }

            &.error {
                input {
                    background: #FFEFF0;
                    box-shadow: inset 0px -2px 0px #EB5757;
                    border-radius: 10px 10px 0px 0px;
                }

                label {
                    color: #EB5757;
                }
            }

            &.valid {
                input {
                    background: #F6F6F7;
                    box-shadow: inset 0px -2px 0px #00CC45;
                    border-radius: 10px 10px 0px 0px;
                }

                label {
                    color: #4AC054;
                }
            }

        }
    }

    .form_row_checbox {
        padding-right: 50px;

        @include mediaToDown($container-mobile) {
            padding-right: 0;
        }
    }
}

.form_row_emit_select {
    position: relative;
    z-index: 2;
    width: 508px;

    input {
        height: 80px;
        width: 100%;
        padding: 20px;
        padding-top: 34px;
        border: none;
        border-radius: 10px;
        background: #F0F2F6;
        color: #4D4D59;
        font-weight: 500;
        font-size: 20px;
        font-family: $font-family-text;
        cursor: default;

        &:hover ~ label, &:not(:placeholder-shown) ~ label {
            font-size: 16px;
            top: 28%;
            left: 20px;

            @include mediaToDown($container-mobile) {
                font-size: 12px;
            }

            &.error {
                font-size: 16px;
                color: #ff6567;
                top: auto;
                bottom: -20px;
                left: 2px;
                transform: translateY(0);

                @include mediaToDown($container-mobile) {
                    font-size: 12px;
                }
            }
        }

        &:hover,
        &:focus {
            border-color: #ffb701;
        }

        @include mediaToDown($container-mobile) {
            height: 60px;
            font-size: 18px;
            line-height: 28px;
        }
    }

    &.error {
        input {
            background: #FFEFF0;
            box-shadow: inset 0px -2px 0px #EB5757;
            border-radius: 10px 10px 0px 0px;
            color: #EB5757;
        }

        label {
            color: #EB5757;
        }
    }

    &.valid {
        input {
            background: #F6F6F7;
            box-shadow: inset 0px -2px 0px #00CC45;
            border-radius: 10px 10px 0px 0px;
        }

        label {
            color: #4AC054;
        }
    }

    label {
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
        font-size: 24px;
        color: color('grey');
        pointer-events: none;
        transition: all 0.2s ease;

        &.error {
            font-size: 13px;
            color: #ff6567;
            top: auto;
            bottom: -20px;
            left: 2px;
            transform: translateY(0);
        }
    }
}

.form_row_select_custom {
    position: relative;
    height: 80px;
    @include mediaToDown($container-mobile) {
        height: 60px;
    }

    &.select_open {
        label {
            font-size: 16px;
            top: 28%;
            left: 20px;
            @include mediaToDown($container-mobile) {
                font-size: 12px;
                left: 14px;
            }
        }
    }

    &:after {
        content: '';
        position: absolute;
        right: 32px;
        top: 50%;
        transform: translateY(-50%);
        width: 19px;
        height: 13px;
        background: url('../../images/select_arrow.svg') no-repeat center center/contain;
        pointer-events: none;

        @include mediaToDown($container-tablet) {
            right: 17px;
            width: 17px;
            height: 11px;
        }
    }

    input {
        height: 80px;
        width: 100%;
        padding: 20px;
        padding-top: 8px !important;
        border: none;
        border-radius: 10px;
        background: #F0F2F6;
        color: #4D4D59;
        font-weight: 500;
        font-size: 20px;
        font-family: $font-family-text;
        cursor: pointer;

        &.input_focus ~ label {
            font-size: 16px;
            top: 28%;
            left: 20px;

            @include mediaToDown($container-mobile) {
                font-size: 12px;
                left: 14px;
            }
        }

        @include mediaToDown($container-mobile) {
            height: 60px;
            font-size: 18px;
            line-height: 28px;
            padding-left: 14px;
            padding-right: 42px;
        }
    }

    label {
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
        font-size: 24px;
        color: color('grey');
        pointer-events: none;
        transition: all 0.2s ease;

        &.error {
            font-size: 13px;
            color: #ff6567;
            top: auto;
            bottom: -20px;
            left: 2px;
            transform: translateY(0);
        }

        @include mediaToDown($container-mobile) {
            font-size: 18px;
            left: 14px;
        }
    }

    ul {
        position: absolute;
        top: 74px;
        left: 0;
        width: 100%;
        max-height: 360px;
        background: #F0F2F6;
        overflow: hidden;
        overflow-y: scroll;
        opacity: 0;
        visibility: hidden;
        z-index: -1;

        @include mediaToDown($container-mobile) {
            top: 54px;
        }

        &.open {
            opacity: 1;
            transform: none;
            visibility: visible;
            transition: opacity 251ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 167ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            z-index: 500;
        }

        li {
            padding: 16px 20px;
            cursor: pointer;
            font-size: 20px;
            line-height: 28px;
            color: #4D4D59;
            transition: background-color .2s ease;

            &:hover, .active {
                background: #4AC054;
                color: #fff;
                font-weight: 500;
            }
        }
    }
}
