.section_footer {
    background: #F0F2F6;

    @include mediaToDown($container-mobile) {
        background: #fff;
        margin-top: 36px;
    }
}

.footer {
    padding-top: 110px;
    padding-bottom: 130px;

    @include mediaToDown($container-mobile) {
        padding-top: 30px;
        padding-bottom: 40px;
    }
}

.footer_info_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 50px;
    margin-top: 122px;
    border-top: 1px solid #dfe4eb;

    @include mediaToDown($container-mobile) {
        margin-top: 30px;
        padding-top: 25px;

        .logo_wrap {
            display: none;
        }
    }
}

.footer_info {
    width: 690px;
    font-size: 12px;
    line-height: 16px;
    color: color('grey');

    a {
        color: #4eb857;
    }

    @include mediaToDown($container-tablet) {
        width: 60%;
    }

    @include mediaToDown($container-mobile) {
        width: 100%;
        font-size: 10px;
        line-height: 15px;
    }
}
