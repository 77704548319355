.section_feedback {
    padding-top: 106px;

    @include mediaToDown($container-mobile) {
        padding-top: 40px;
    }
}

.feedback_list_wrap {

    @include mediaToDown($container-tablet) {
        overflow: hidden;
        width: calc(100% + 30px);
        margin-left: -15px;
    }
}

.feedback_list {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;

    @include mediaToDown($container-tablet) {
        overflow-x: auto;
        margin-bottom: -15px;
        padding-bottom: 15px;
        padding-left: 15px;
        padding-right: 15px;
    }
}

.feedback_item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 387px;
    height: 100%;
    padding: 35px 30px;
    padding-bottom: 40px;
    background: #f0f2f6;
    border-radius: 40px;

    &:before {
        content: '';
        position: absolute;
        top: -6px;
        right: 0;
        width: 50px;
        height: 31px;
        background: url('../../images/feedback_elem.svg') no-repeat center center/cover;

        @include mediaToDown($container-mobile) {
            width: 32px;
            height: 20px;
            justify-content: flex-start;
        }
    }

    @include mediaToDown($container-tablet) {
        margin-right: 30px;
    }

    @include mediaToDown($container-mobile) {
        width: 250px;
        margin-right: 15px;
        background: #fff;
        border-radius: 10px;
        padding: 17px 20px 23px 20px;
    }
}

.feedback_text {
    font-size: 20px;
    line-height: 28px;

    @include mediaToDown($container-mobile) {
        font-size: 14px;
        line-height: 22px;
    }
}

.feedback_user_info {
    display: flex;
    align-items: center;
    padding-top: 68px;

    @include mediaToDown($container-mobile) {
        padding-top: 30px;
    }
}

.feedback_user_avatar {
    width: 77px;
    height: 77px;
    border-radius: 10px;
    overflow: hidden;

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        font-family: 'object-fit: cover; object-position: center;';
    }

    @include mediaToDown($container-mobile) {
        width: 50px;
        height: 50px;
    }
}

.feedback_user_block {
    width: 225px;
    margin-left: 17px;

    @include mediaToDown($container-mobile) {
        margin-left: 13px;
        margin-top: 2px;
    }
}

.feedback_user_name {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;

    @include mediaToDown($container-mobile) {
        font-size: 14px;
        line-height: 16px;
    }
}

.feedback_user_region_wrap {
    position: relative;
    margin-top: 8px;

    @include mediaToDown($container-mobile) {
        margin-top: 5px;
    }

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 15px;
        height: 20px;
        background: url('../../images/point.svg') no-repeat center center/cover;

        @include mediaToDown($container-mobile) {
            top: 45%;
            width: 8px;
            height: 11px;
        }
    }
}

.feedback_user_region {
    padding-left: 21px;
    font-size: 14px;
    line-height: 17px;
    color: color('grey');
    @include text-truncate();

    @include mediaToDown($container-mobile) {
        font-size: 11px;
        line-height: 13px;
        padding-left: 12px;
    }
}
