.section_questions {
  padding-top: 120px;

  @include mediaToDown($container-mobile) {
    padding-top: 25px;
  }
}

.question_head {
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  cursor: pointer;

  span {
    display: inline-block;
    width: 36px;
    color: color('grey');
    font-weight: 400;
  }

  @include mediaToDown($container-mobile) {
    font-size: 16px;
    line-height: 24px;
  }
}

.question_list {
  position: relative;
  padding-top: 60px;
  padding-bottom: 120px;

  @include mediaToDown($container-mobile) {
    padding-top: 20px;
    padding-bottom: 0;
  }
}

.question_list_img {

  position: absolute;
  right: 0;
  bottom: 0;
  width: 340px;
  height: 365px;
  pointer-events: none;

  @include mediaToDown(1400px) {
    right: 100px;
  }

  @include mediaToDown($container-tablet) {
    display: none;
  }
}

.question_item {
  max-width: 910px;
  width: 100%;
  margin-bottom: 54px;

  &:last-child {
    margin-bottom: 0;
  }

  @include mediaToDown($container-mobile) {
    margin-bottom: 20px;
  }
}

.question_info {
  padding-top: 20px;
  padding-left: 35px;
  font-size: 20px;
  line-height: 30px;

  @include mediaToDown($container-mobile) {
    padding-top: 12px;
    padding-left: 0;
    font-size: 14px;
    line-height: 22px;
  }
}

.ReactCollapse--collapse {
  transition: height 500ms;
}
