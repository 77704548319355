.header_wrap {
    padding-top: 40px;
    padding-bottom: 280px;
    background: #F0F2F6;

    @include mediaToDown($container-mobile) {
        padding-top: 17px;
        padding-bottom: 0;
    }
}

.header_info_wrap {

    @include mediaToDown($container-mobile) {
        display: none;
    }
}

.header {
    display: flex;
    align-items: center;
    padding-bottom: 10px;

    @include mediaToDown($container-mobile) {
        align-items: flex-end;
        padding-bottom: 0;
        margin-bottom: 20px;
    }
}

.logo {
    width: 230px;

    img {
        display: block;
        width: 100%;
    }

    @include mediaToDown($container-mobile) {
        width: 140px;
    }
}

.header_text {
    position: relative;
    color: color('grey');
    font-size: 20px;
    line-height: 24px;
    margin-left: 100px;

    &:before {
        content: '';
        position: absolute;
        left: -56px;
        top: 50%;
        transform: translateY(-50%);
        width: 19px;
        height: 4px;
        background: url('../../images/icon.png') no-repeat center center/cover;

        @include mediaToDown($container-tablet) {
            left: -32px;
        }

        @include mediaToDown($container-mobile) {
            left: auto;
            top: -13px;
            right: 0;
        }
    }

    @include mediaToDown($container-tablet) {
        font-size: 15px;
        margin-left: 50px;
    }

    @include mediaToDown($container-mobile) {
        margin-left: auto;
        font-size: 6px;
        line-height: 1;
    }
}

.header_phone {
    margin-left: auto;
    margin-top: 7px;
    @include mediaToDown($container-mobile) {
        position: fixed;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 90px;
        background: #fff;
        z-index: 10;
        box-shadow: 0 6px 8px 8px rgba(0, 0, 0, .2);
    }
    a {
        position: relative;
        text-decoration: none;
        font-size: 20px;
        font-weight: 500;

        span {
            &.not_mobile {
                @include mediaToDown($container-mobile) {
                    display: none;
                }
            }

            &.with_icon {
                position: relative;
                &:before {
                    content: '';
                    display: inline-block;
                    position: relative;
                    top: 2px;
                    margin-right: 10px;
                    width: 20px;
                    height: 20px;
                    background: url('../../images/phone_white.svg') no-repeat center center/cover;
                }
            }
        }

        @include mediaToDown($container-tablet) {
            font-size: 15px;
        }

        @include mediaToDown($container-mobile) {
            height: 58px;
            margin-top: 14px;
            font-size: 22px;
            font-weight: 500;
            line-height: 22px;
            padding: 15px 20px;
            width: 90%;
            border-radius: 10px;
            box-shadow: inset 0px -2px 0px #009D35;
            transition: all 0.2s ease;
            background: #4EB857;
            color: #fff;
            text-align: center;

            &:hover {
                background: #1EB12A;
                box-shadow: inset 0px -2px 0px #005568;
            }

            &:active {
                box-shadow: inset 0px 0 0px #009D35;
            }
        }

        &:before {
            content: '';
            position: absolute;
            left: -26px;
            top: 50%;
            transform: translateY(-50%);
            width: 20px;
            height: 20px;
            background: url('../../images/phone.svg') no-repeat center center/cover;
            @include mediaToDown($container-mobile) {
                content: none;
            }
        }
    }
}

.header_info {
    display: flex;

    @include mediaToDown($container-tablet) {
        flex-wrap: wrap;
        justify-content: center;
        padding-top: 20px;
    }
}

.header_info_item {
    position: relative;
    display: flex;
    align-items: flex-end;
    width: 365px;
    z-index: 1;

    &.item_1 {
        .header_info_item_img_wrap {
            @include mediaToDown($container-mobile) {
                height: 64px;
                margin-top: -10px;
                margin-right: 26px;
                overflow: hidden;
                order: 1;
            }
        }

        .header_info_item_img {
            width: 102px;

            @include mediaToDown($container-mobile) {
                width: 54px;
            }
        }

        .header_info_item_number {
            left: 122px;

            @include mediaToDown($container-mobile) {
                left: 6px;
            }
        }

        .header_info_item_text {
            margin-left: 20px;

            @include mediaToDown($container-mobile) {
                margin-left: 60px;
            }
        }
    }

    &.item_2 {
        width: 425px;

        .header_info_item_img_wrap {
            @include mediaToDown($container-mobile) {
                height: 65px;
                margin-top: -12px;
                overflow: hidden;
                order: 1;
            }
        }

        .header_info_item_img {
            width: 190px;

            @include mediaToDown($container-mobile) {
                width: 100px;
                height: 55px;
                margin-top: -21px;
            }
        }

        .header_info_item_number {
            left: 160px;

            @include mediaToDown($container-mobile) {
                left: 6px;
            }
        }

        .header_info_item_text {
            margin-left: -26px;

            @include mediaToDown($container-mobile) {
                margin-left: 60px;
            }
        }

        @include mediaToDown($container-mobile) {
            width: 100%;
        }
    }

    &.item_3 {
        .header_info_item_img_wrap {
            @include mediaToDown($container-mobile) {
                height: 64px;
                margin-top: -10px;
                margin-right: 8px;
                overflow: hidden;
                order: 1;
            }
        }

        .header_info_item_img {
            width: 165px;

            @include mediaToDown($container-mobile) {
                width: 90px;
            }
        }

        .header_info_item_number {
            left: 150px;

            @include mediaToDown($container-mobile) {
                left: 6px;
            }
        }

        .header_info_item_text {
            margin-left: -18px;

            @include mediaToDown($container-mobile) {
                margin-left: 60px;
            }
        }
    }

    img {
        display: block;
        width: 100%;
    }

    @include mediaToDown($container-tablet) {
        margin-bottom: 20px;
    }

    @include mediaToDown($container-mobile) {
        width: 100%;
        height: 55px;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        background: #fff;
        border-radius: 10px;
    }
}

.header_info_item_number {
    position: absolute;
    left: 0;
    bottom: -15px;
    font-weight: 500;
    font-size: 144px;
    line-height: 144px;
    color: #fff;
    z-index: -1;

    @include mediaToDown($container-mobile) {
        top: 0;
        color: #F0F2F6;
        font-size: 80px;
        line-height: 80px;
    }
}

.header_info_item_text {
    line-height: 26px;
    margin-bottom: 10px;
    color: color('grey');

    @include mediaToDown($container-mobile) {
        font-size: 12px;
        line-height: 17px;
        margin-top: 10px;
    }
}

.mobile_component {
    display: none;
    transform-origin: top;
    transform: scaleY(0);
    transition: all .3s ease;
    height: 0;

    &.step_visible {
        transform: scaleY(1);
        height: 200px;
    }

    @include mediaToDown($container-mobile) {
        display: block;

        .header_info {
            padding-top: 0;
            margin-bottom: 10px;
        }
    }
}
